<template>
  <div class="wrap">
    <!-- header : END -->
    <!-- header : END -->
    <!-- body : START -->
    <div class="layout_body tutorial">
      <!-- Swiper -->
      <swiper ref="sildes" class="swiper tutorial_swiper" :options="options">
        <swiper-slide>
          <div class="tutorial_box">
            <div class="tuto_txt">
              <h6>{{ $t('Tutorial.TutorialTit1') }}</h6>
              <p v-html="$t('Tutorial.TutorialTxt1')"></p>
            </div>
            <image-block-file fileName="tuto01_M.png" class="tuto_img" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="tutorial_box">
            <div class="tuto_txt">
              <h6 v-html="$t('Tutorial.TutorialTit2')"></h6>
              <p v-html="$t('Tutorial.TutorialTxt2')"></p>
            </div>
            <image-block-file fileName="tuto02_M.png" class="tuto_img" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="tutorial_box">
            <div class="tuto_txt">
              <h6>{{ $t('Tutorial.TutorialTit3') }}</h6>
              <p v-html="$t('Tutorial.TutorialTxt3')"></p>
            </div>
            <image-block-file fileName="tuto03_M.png" class="tuto_img" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="tutorial_box">
            <div class="tuto_txt">
              <h6 v-html="$t('Tutorial.TutorialTit4')"></h6>
              <p v-html="$t('Tutorial.TutorialTxt4')"></p>
            </div>
            <image-block-file fileName="tuto04_M.png" class="tuto_img" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="tutorial_box">
            <div class="tuto_txt">
              <h6 v-html="$t('Tutorial.TutorialTit5')"></h6>
              <p v-html="$t('Tutorial.TutorialTxt5')"></p>
            </div>
            <image-block-file fileName="tuto05_M.png" class="tuto_img" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="tutorial_box">
            <div class="tuto_txt">
              <h6>{{ $t('Tutorial.TutorialTit6') }}</h6>
              <p v-html="$t('Tutorial.TutorialTxt6')"></p>
            </div>

            <image-block-file fileName="tuto06_M.png" class="tuto_img" />
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
      <div class="w100_btn_box">
        <button class="align_left" @click="handleClickFinish">
          {{ $t('Join.Skip') }}
        </button>
        <button
          v-if="isLastPage === false"
          :class="`swiper-button-next align_right`"
        ></button>
        <button
          v-else
          :class="`align_right finish-button`"
          @click="handleClickFinish"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import ImageBlockFile from '../components/ui/ImageBlock/ImageBlockFile.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { ref } from 'vue-demi';

export default {
  components: { ImageBlockFile, Swiper, SwiperSlide },
  setup(_, context) {
    const isLastPage = ref(false);

    const options = {
      mousewheel: true,
      clickable: true,
      direction: 'horizontal',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
      },
      on: {
        activeIndexChange() {
          const len = this.slides.length;
          const active = this.activeIndex;

          isLastPage.value = len === active + 1;
        },
      },
    };

    return {
      options,
      isLastPage,
      handleClickFinish() {
        localStorage.setItem('is_tutorial', 'true');
        context.root.$router.replace('/');
      },
    };
  },
};
</script>

<style>
.tutorial {
  position: relative;
}
.tutorial_swiper .swiper-slide {
  position: relative;
  height: 100vh;
  justify-content: center;
  z-index: 100;
  padding: 90px 0 0 0;
}
.tutorial_box {
  position: relative;
  display: block;
  height: 100%;
}
.tuto_img {
  position: absolute;
  display: block;
  width: 100%;
  height: calc(100% - 200px);
  bottom: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
}
</style>
